import AbstractApiRequest from '@/api/apimodel/abstract-api-request'

export default class PutCreditRequest extends AbstractApiRequest {
  // オーダーID
  orderId = ''
  // 利用金額
  amount = ''
  // 組合員証番号
  memberCardId = ''
  // トークン
  token = ''
  // 戻りURL
  retUrl = ''
}
