<template>
  <div class="pc-wrap memberid-signup-main">
    <v-container class="mb-8">
      <div class="py-3 text-h6 memberid-red--text font-weight-bold">※まだお申し込みは完了しておりません</div>
      <h3 class="memberid-black-600--text title font-weight-bold">内容のご確認</h3>
      <p class="mt-2">ご入力内容にお間違いがないかご確認の上、お申込みください。</p>
      <v-sheet class="rounded-lg memberid-black-100 pa-4 mt-8">
        <div class="mx-3 my-2">
          <title-component title="出資金"></title-component>
          <div class="d-flex justify-space-between">
            <div>{{ this.capital.strQuantity }}口</div>
            <div>{{ this.capital.getAmount() }}円</div>
          </div>
          <title-component title="お支払い方法"></title-component>
          <div v-if="member.selectedDelivery === 0 && capital.selectedPay === 0">ご登録のクレジットカード</div>
          <div v-else>ご登録の銀行口座（引落日 毎月２６日）</div>
        </div>
      </v-sheet>
      <br />
      <v-sheet class="rounded-lg memberid-black-100 pa-4 mt-8">
        <div class="mx-3 my-2">
          <title-component title="組合員情報"></title-component>
          <confirm-item title="お名前" :content="member.fullName" />
          <confirm-item title="フリガナ" :content="member.fullNameKana" />
          <confirm-item title="生年月日" :content="member.birthDate" />
          <confirm-item title="性別" :content="memberSex" />
          <confirm-item title="住所" :contents="memberAddress" />
          <confirm-item title="電話番号" :content="member.phoneNumber" />
          <confirm-item title="メール" :content="member.email" />
          <confirm-item title="宅配" :content="deliveryStatus" />
          <confirm-item title="子育て" :content="childStatus" />
        </div>
      </v-sheet>
      <div class="d-flex justify-end">
        <v-btn
          text
          @click="backToMemberPage()"
          class="px-0 mt-1 memberid-black-400--text subtitle-1"
          style="text-decoration: underline"
        >
          組合員情報の入力に戻る</v-btn
        >
      </div>
      <v-sheet v-if="showChildInfo" class="rounded-lg memberid-black-100 pa-4 mt-8">
        <div class="mx-3 my-2">
          <title-component title="お子様の情報"></title-component>
          <div class="mt-2 pt-2">{{ aboutChildrenTextList[child.selectedAboutChildren] }}</div>

          <confirm-item v-if="isSelectChild" title="お子様のお名前" :content="child.childName" />
          <confirm-item :title="childDateTitle" :content="child.childDate" />
          <div class="mt-2 pt-2">
            <v-row>
              <v-col col="2" md="2"> {{ childImageTitle }} : </v-col>
              <v-col cols="8">
                <v-sheet
                  v-if="!isEmptyForImage"
                  style="position: relative"
                  flat
                  width="140"
                  height="auto"
                  class="ml-2 mb-2"
                >
                  <v-img :src="child.image.fileUrl" :contain="true"></v-img>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-sheet>
      <div v-if="showChildInfo" class="d-flex justify-end">
        <v-btn
          text
          @click="backToChildPage()"
          class="px-0 mt-1 memberid-black-400--text subtitle-1"
          style="text-decoration: underline"
        >
          お子様情報の入力に戻る</v-btn
        >
      </div>
      <v-sheet v-if="showDeliveryInfo" class="rounded-lg memberid-black-100 pa-4 mt-8">
        <div class="mx-3">
          <div class="my-2">
            <title-component title="宅配の配達先情報"></title-component>
            <confirm-item title="配達先" :contents="deliveryAddress" />
            <confirm-item title="連絡先" :content="deliveryPhoneNumber" />
            <confirm-item title="お住まい" :content="deliveryResidence" />
            <confirm-item title="置き配時の置き場所" :content="deliveryPlacement" />
            <confirm-item title="お届け時の確認" :content="deliveryConfirmationOfDelivery" />
            <confirm-item title="カタログ・納品書等一式の配付申込" :content="deliverySelectionOfPaperCatalog" />
            <confirm-item v-if="isOpenTodokTrialCampaign" title="【期間限定】トドックおためしキャンペーンお申込み" :content="todokTrialCampaign" />
            <confirm-item title="メール" :content="delivery.email" />
          </div>

          <div class="my-5 pt-2">
            <title-component title="組合員さんからのご紹介ですか？"></title-component>
            <div class="mt-2 pt-2">{{ introduceText }}</div>
            <div v-if="this.delivery.selectedIntroducer === 1">
              <confirm-item title="ご紹介者のお名前" :content="delivery.introducerName" />
              <confirm-item
                v-if="showIntroducerUnionNumber"
                title="組合員番号"
                :content="delivery.introducerUnionNumber"
              />
              <confirm-item
                v-if="showIntroducerPhoneNumber"
                title="電話番号"
                :content="delivery.introducerPhoneNumber"
              />
            </div>
          </div>
        </div>
      </v-sheet>
      <div v-if="showDeliveryInfo" class="d-flex justify-end">
        <v-btn
          text
          @click="backToDeliveryPage()"
          class="px-0 mt-1 memberid-black-400--text subtitle-1"
          style="text-decoration: underline"
        >
          配達先情報の入力に戻る</v-btn
        >
      </div>
      <v-container class="d-flex justify-center mb-6" style="width: 70%">
        <div style="width: 60%" class="justify-center">
          <v-layout justify-start>
            <v-checkbox v-model="acceptPrivacyPolicy" color="memberid-primary" hide-details>
              <span slot="label">
                <a
                  style="text-decoration: underline"
                  class="px-0 mt-1 memberid-black-400--text subtitle-1"
                  @click.stop
                  href="https://www.sapporo.coop/corporate/content/?id=60"
                  target="_blank"
                  rel="noopener noreferrer"
                  >個人情報保護方針</a
                >に同意する
              </span>
            </v-checkbox>
          </v-layout>
          <v-layout justify-start>
            <v-checkbox v-model="acceptTerms" color="memberid-primary" hide-details>
              <span slot="label">
                <a
                  style="text-decoration: underline"
                  class="px-0 mt-1 memberid-black-400--text subtitle-1"
                  @click.stop
                  href="https://todok.sapporo.coop/static/policy/web/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  >サービス利用規約</a
                >に同意する
              </span>
            </v-checkbox>
          </v-layout>
          <v-layout justify-start>
            <v-checkbox v-model="confirmArticlesAndTerms" color="memberid-primary" hide-details>
              <span slot="label">
                <a
                  style="text-decoration: underline"
                  class="px-0 mt-1 memberid-black-400--text subtitle-1"
                  @click.stop
                  href="https://www.sapporo.coop/corporate/content/?id=22"
                  target="_blank"
                  rel="noopener noreferrer"
                  >定款・諸規約集</a
                >を確認した
              </span>
            </v-checkbox>
          </v-layout>
          <v-layout justify-start v-if="showDeliveryInfo">
            <v-checkbox
              v-model="acceptSpecialNoteDelivery"
              color="memberid-primary"
              label="下記の特記事項に同意する"
              hide-details
              ><div slot="label">下記の特記事項に同意する</div></v-checkbox
            >
          </v-layout>
        </div>
      </v-container>

      <v-sheet class="rounded-lg my-6 pa-3 body-2" color="memberid-black-100">
        <p class="mb-0">
          ・<span class="font-weight-bold"
            >組合員のお申込みは登録完了後のキャンセルはできません。脱退のお申し出が必要となりますのでご注意ください。
          </span>
        </p>
        <p class="mb-0" v-if="showDeliveryInfo">
          ・<span class="font-weight-bold"
            >ご利用開始から6か月間はご注文金額が1週間に2万円未満の上限設定があります。</span
          ><br />
          <span class="memberid-red--text"
            >・<span class="font-weight-bold"
              >ご本人確認・ご住所の確認ができない場合、トドックのご利用ができません。
            </span></span
          >
        </p>
      </v-sheet>
      <rounded-button buttonText="この内容で申し込む" :nextPage="nextPage" :isdisabled="!validate()" />
    </v-container>
  </div>
</template>
<script>
// import Vue from 'vue'
import ConfirmItem from '@/components/ConfirmItem'
import TitleComponent from '@/components/TitleComponent'
import RoundedButton from '@/components/RoundedButtonComponent'
import Member from '@/models/member'
import Delivery from '@/models/delivery-information'
import Child from '@/models/child-information'
import Capital from '@/models/capital'
import store from '@/store'
import storage from '@/storage'
import Vue from 'vue'
import DeliveryInformationMixin from '@/components/mixins/delivery-information-mixin'
import memberidApiClient from '@/api/memberid-api-client'
import SubmitClaimRequest from '@/api/apimodel/submitclaim-request'
import ReserveMemberRequest from '@/api/apimodel/reservemember-request'
import ReserveMemberDeliveryRequest from '@/api/apimodel/reservemember-delivery-request'
import PutCredit3DsecurityRequest from '@/api/apimodel/putcredit3Dsecurity-request'
import { getInstance as getAuth0Instance } from '@/auth'
import StringUtil from '@/lib/string-util'
import Constants from '@/lib/constants'

export default {
  name: 'MemberConfirm',
  mixins: [DeliveryInformationMixin],
  components: {
    ConfirmItem,
    TitleComponent,
    RoundedButton,
  },
  data() {
    return {
      member: new Member(),
      delivery: new Delivery(),
      child: new Child(),
      capital: new Capital(),
      acceptPrivacyPolicy: false,
      acceptTerms: false,
      acceptSpecialNoteDelivery: false,
      confirmArticlesAndTerms: false,
      confirmValid: false,
      shoporderNumber: '',
      accessInfo: {},
      isOpenTodokTrialCampaign: Constants.FEATURE_FLAG_OPEN_TODOK_TRIAL_CAMPAIGN,
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!from || from.name === null) {
      if (!storage.getMemberInfo()) {
        next({ name: 'MemberEntry' })
      }
    }
    next()
  },
  async mounted() {
    // まず消す
    storage.removeLastViewIsConfirm()
    this.member = await store.dispatch('getMember')
    this.delivery = await store.dispatch('getDeliveryInformation')
    this.child = await store.dispatch('getChildInformation')
    this.capital = await store.dispatch('getCapital')
    this.amount = this.capital.getAmount()
    // console.log('>>> shoporderNumber >', this.shoporderNumber)
    // 組合員情報がなければ入力に戻す
    if (!storage.getMemberInfo()) {
      store.dispatch('routerPushWithFromQuery', {
        name: 'MemberEntry',
      })
      return
    }
    storage.setLastViewIsConfirm(true)
    this.shoporderNumber = storage.getShopOrderNumber()
    this.accessInfo = storage.getAccessInfo()
    // selectedDelivery === 0 → 宅配利用なし
    // capital.selectedPay === 0 → クレジットカード
    // !this.shoporderNumber migi → 請求番号持ってない
    if(this.member.selectedDelivery === 0 && this.capital.selectedPay === 0){      
      //ブラウザバックなどでnullになった場合、入力画面に戻す対策
      if(storage.getAccessInfo() == null){
        Vue.$showSnackbar({
          title: 'クレジット決済でエラーが発生しました',
          message: '再度支払い方法のご登録をお願いします',
        })
        store.dispatch('routerPushWithFromQuery', {
          name: 'CapitalEntry',
        })
        return
      }
      //失敗して再度登録しなおした場合用に初期化する
      if(storage.getCreditIsConfirm() == false){
      //初期化
        storage.setCreditIsConfirm(null);
      }
      //画面遷移対策(trueなら処理を飛ばす)
      if(!storage.getCreditIsConfirm()){
        //クレジットカード決済 3DS2.0（3DS2.0認証後決済実行）
        const putCredit3DsecurityRequest = new PutCredit3DsecurityRequest()
        putCredit3DsecurityRequest.accessId = this.accessInfo.accessId
        putCredit3DsecurityRequest.accessPass = this.accessInfo.accessPass
        await memberidApiClient
          .putCredit3DSecurity(putCredit3DsecurityRequest)
          .then((putCredit3DSecurityResponse) => {
            putCredit3DsecurityRequest.defaultErrorProcess(putCredit3DSecurityResponse)         
            if (putCredit3DSecurityResponse.data.serviceResponseCode !== '000000') {  
              storage.setCreditIsConfirm(false)
              if(putCredit3DSecurityResponse.data.serviceResponseMsgCode == 'E99999999'){
                Vue.$showSnackbar({
                  title: 'クレジット決済でエラーが発生しました',
                  message: '決済処理に失敗しました。申し訳ございませんが、再度支払い方法のご登録をお願いします。' +
                  '詳しくはご利用のカード会社にお問い合わせください。詳細コード:'+ putCredit3DSecurityResponse.data.serviceResponseCode,
                })
              } else {
                Vue.$showSnackbar({
                  title: 'クレジット決済でエラーが発生しました',
                  message: putCredit3DSecurityResponse.data.serviceResponseMessage,
                })
              }
              store.dispatch('routerPushWithFromQuery', {
                name: 'CapitalEntry',
              })
              return
            }
            else {
            //画面遷移対策 3DS2.0認証後決済実行（SecureTran2）の正否を保持する
              storage.setCreditIsConfirm(true)
            }
        })
        .catch((e) => {
          putCredit3DsecurityRequest.defaultErrorProcess(e)
          Vue.$showSnackbar({
            title: 'システムエラーが発生しました',
            message: '再度アクセスしてください',
          })
          store.dispatch('routerPushWithFromQuery', {
            name: 'CapitalEntry',
          })
          return
        })
      } 
    }
    if (this.member.selectedDelivery === 0 && this.capital.selectedPay === 0 && !this.shoporderNumber) {
      Vue.$showSnackbar({
        title: '登録内容が変更になりました',
        message: '再度支払い方法のご登録をお願いします',
      })
      store.dispatch('routerPushWithFromQuery', {
        name: 'CapitalEntry',
      })
      return
    }
    if (this.member.selectedDelivery === 1 && this.delivery.selectedAddress === null) {
      Vue.$showSnackbar({
        title: '宅配情報の入力',
        message: '宅配情報を入力してください',
      })
      store.dispatch('routerPushWithFromQuery', {
        name: 'DeliveryInformation',
      })
      return
    }
    if (this.member.selectedDelivery === 1 || this.capital.selectedPay === 1) {
      // 口座登録の場合
      if (!storage.isAccountTransferStatus()) {
        Vue.$showSnackbar({
          title: '宅配利用には口座登録が必要です',
          message: '変更する場合、ブラウザを閉じて最初からやり直してください',
        })
        store.dispatch('routerPushWithFromQuery', {
          name: 'CapitalEntry',
        })
        return
      }
    }
  },
  methods: {
    backToDeliveryPage() {
      store.dispatch('routerPushWithFromQuery', { name: 'DeliveryInformation' })
    },
    backToChildPage() {
      store.dispatch('routerPushWithFromQuery', { name: 'ChildInformation' })
    },
    backToMemberPage() {
      store.dispatch('routerPushWithFromQuery', { name: 'MemberEntry' })
    },
    backToCapitalPage() {
      store.dispatch('routerPushWithFromQuery', { name: 'CapitalEntry' })
    },
    validate() {
      if (this.showDeliveryInfo) {
        return (
          this.acceptPrivacyPolicy &&
          this.acceptTerms &&
          this.confirmArticlesAndTerms &&
          this.acceptSpecialNoteDelivery
        )
      } else {
        return (
          this.acceptPrivacyPolicy &&
          this.acceptTerms &&
          this.confirmArticlesAndTerms
        )
      }
    },
    async nextPage() {
      if (!this.validate()) {
        return
      }
      Vue.$startLoading()

      // 組合員管理システムに先に登録
      const memberRequest = await this.setRequestData()
      const memberResponse = await memberidApiClient
        .reserveMember(memberRequest)
        .catch((error) => {
          memberRequest.defaultErrorProcess(error)
          Vue.$endLoading()
          Vue.$showSnackbar({
            title: '組合員情報の登録に失敗しました。',
            message: '再度お申し込みをお願いいたします。',
          })
          return
        })
        .finally(() => {
          Vue.$endLoading()
        })
      // 正常終了
      if (memberResponse) {
        const auth0 = getAuth0Instance()
        const pathName = this.member.selectedDelivery === 1 ? '/deliveryComplete' : '/complete'
        const redirectUrl = window.location.origin + pathName

        // 宅配の場合は宅配完了画面
        if (this.member.selectedDelivery === 1 || this.capital.selectedPay === 1) {
          Vue.$endLoading()
          storage.setCompMemberCode(this.capital.memberCardId)
          storage.setCompMemberName(this.member.fullName)

          window.history.replaceState({}, document.title, window.location.origin)
          Vue.$endLoading()
          auth0.logout({ returnTo: redirectUrl })
          return
        }
        // 宅配じゃないかつクレジットカードのときは請求確定する
        if (this.member.selectedDelivery === 0 && this.capital.selectedPay === 0) {
          // 完了画面は宅配やってるやってないで振り分け
          const submitClaimRequest = new SubmitClaimRequest()
          submitClaimRequest.accessId = this.accessInfo.accessId
          submitClaimRequest.accessPass = this.accessInfo.accessPass
          submitClaimRequest.amount = this.capital.getAmount()
          await memberidApiClient
            .submitClaim(submitClaimRequest)
            .then((response) => {
              // console.log('請求確定 response -> ', response)
              if (response.data.serviceResponseCode === '000000') {
                // console.log('請求確定 成功=>')

                storage.setCompMemberCode(this.capital.memberCardId)
                storage.setCompMemberName(this.member.fullName)

                window.history.replaceState({}, document.title, window.location.origin)
                auth0.logout({ returnTo: redirectUrl })
                Vue.$endLoading()
                return
              } else {
                // console.log('請求確定 レスポンスエラー ')
                // Vue.$showSnackbar({
                //   title: 'システムエラーが発生しました',
                //   message: 'もう一度最初からやり直してください',
                // })
                // 請求確定できなくても組合員登録できてしまっている。
                // ここで失敗しても組合員さまにはどうにもできないので完了画面まで遷移し、
                // 請求確定は決済ステーションで行う
                storage.setCompMemberCode(this.capital.memberCardId)
                storage.setCompMemberName(this.member.fullName)

                window.history.replaceState({}, document.title, window.location.origin)
                auth0.logout({ returnTo: redirectUrl })
                Vue.$endLoading()
                return
              }
            })
            .catch((e) => {
              console.log('請求確定 error => ', e)
              // submitClaimRequest.defaultErrorProcess(e)
              // Vue.$showSnackbar({
              //   title: 'システムエラーが発生しました',
              //   message: 'もう一度最初からやり直してください',
              // })
              // 請求確定できなくても組合員登録できてしまっている。
              // ここで失敗しても組合員さまにはどうにもできないので完了画面まで遷移し、
              // 請求確定は決済ステーションで行う
              storage.setCompMemberCode(this.capital.memberCardId)
              storage.setCompMemberName(this.member.fullName)

              window.history.replaceState({}, document.title, window.location.origin)
              auth0.logout({ returnTo: redirectUrl })
              Vue.$endLoading()
              return
            })
        }
      } else {
        Vue.$showSnackbar({
          title: '組合員情報の登録に失敗しました。',
          message: '再度お申し込みをお願いいたします。',
        })
      }
      Vue.$endLoading()
    },
    async setRequestData() {
      const reserveMemberRequest = new ReserveMemberRequest()
      reserveMemberRequest.memberCode = this.capital.memberCode
      reserveMemberRequest.memberCardId = this.capital.memberCardId
      reserveMemberRequest.lastName = this.member.lastName
      reserveMemberRequest.firstName = this.member.firstName
      reserveMemberRequest.lastNameKana = this.member.lastNameKana
      reserveMemberRequest.firstNameKana = this.member.firstNameKana
      // TODO 分かりづらいから変更したい
      reserveMemberRequest.sexuality = this.member.sex === 0 ? '2' : '1' //画面は女性:0 男性:1 リクエストは女性 : 2 男性 : 1
      reserveMemberRequest.zipCode = this.member.formatZipCode()
      reserveMemberRequest.address = this.member.formatAddress()
      reserveMemberRequest.tel = StringUtil.formatPhoneNumber(this.member.phoneNumber)
      reserveMemberRequest.mailAddress = this.member.email
      reserveMemberRequest.birthday = this.member.birthDate
      // クレジットカードだったら
      // 宅配なしでクレジットカードを選択した場合
      if (this.member.selectedDelivery === 0 && this.capital.selectedPay === 0) {
        // クレジットカード
        reserveMemberRequest.selectedPay = '0'
      } else {
        // 口座振替
        reserveMemberRequest.selectedPay = '1'
      }
      // selectedPayが0 （クレジット）の時は金額。 1（口座振替）の時は0 を設定する
      reserveMemberRequest.contributionAmount =
        reserveMemberRequest.selectedPay === '0' ? this.capital.getAmount() : '0'
      // 本来の出資金を渡す
      reserveMemberRequest.actualContributionAmount = this.capital.getAmount()
      const auth0 = getAuth0Instance()
      reserveMemberRequest.accessToken = await auth0.getTokenSilently()
      const claim = await auth0.getIdTokenClaims()
      reserveMemberRequest.idToken = claim.__raw
      reserveMemberRequest.refreshToken = auth0.getRefreshToken()
      if (this.member.selectedDelivery === 1) {
        const deliveryRequest = new ReserveMemberDeliveryRequest()
        // 組合員ID経由のデータの場合口座情報を持っているので区別する必要がある
        // この★はその目印
        deliveryRequest.unionNumber = '★' + this.capital.memberCardId
        deliveryRequest.unionLastName = this.member.lastName
        deliveryRequest.unionFirstName = this.member.firstName
        deliveryRequest.unionBirthDate = this.member.birthDate
        deliveryRequest.unionZipCode = this.member.zipCode //こっちはハイフン無しが良い
        deliveryRequest.unionAddress1 = this.member.address1
        deliveryRequest.unionAddress2 = this.member.address2
        deliveryRequest.unionAddress3 = this.member.address3
        deliveryRequest.unionPhoneNumber = this.member.phoneNumber
        // 宅配情報入力画面で入れたほうを重視なければ組合員情報のものを使う
        deliveryRequest.email = this.delivery.email ?? this.member.email
        const dai = this.delivery
        deliveryRequest.houseType = dai.selectedResidence
        deliveryRequest.placementType = dai.selectedPlacement
        deliveryRequest.intercomType = dai.selectedConfirmationOfDelivery === 0
        deliveryRequest.distributePaperCatalogs = dai.selectedDeliveryOfPaperCatalog === 0
        deliveryRequest.requestTodokTrialCampaign = dai.selectedTodokTrialCampaign === 0
        deliveryRequest.termsOfUseFlag = true
        deliveryRequest.from = store.getters.from
        if (dai.selectedAddress === 1) {
          deliveryRequest.zipCode = dai.zipCode
          deliveryRequest.address1 = dai.address1.replace(/北海道/g, '')
          deliveryRequest.address2 = dai.address2
          deliveryRequest.address3 = dai.address3
        }
        if (dai.selectedTel === 1) {
          deliveryRequest.phoneNumber = dai.phoneNumber
        }
        if (dai.selectedPlacement === 4) {
          deliveryRequest.placementOtherInput = dai.placementOtherInput
        }
        if (this.member.selectedChildService === 1) {
          if (this.isSelectChild) {
            // 妊娠していない→トドックサイトでは0〜15再未満に該当
            deliveryRequest.childType = 2
            deliveryRequest.childName = this.child.childName
          } else {
            // 妊娠中
            deliveryRequest.childType = 1
          }
          deliveryRequest.childDate = this.child.childDate
          deliveryRequest.image = this.child.image.fileUrl
        } else {
          deliveryRequest.childType = 0
        }
        if (dai.selectedIntroducer === 1) {
          deliveryRequest.introducerName = dai.introducerName
          if (dai.introducerUnionNumber) {
            deliveryRequest.introducerUnionNumber = dai.introducerUnionNumber
          } else {
            deliveryRequest.introducerPhoneNumber = dai.introducerPhoneNumber
          }
        }
        // 営業担当者コードが設定されているときはパラメータに追加
        const salesPersonEmployeeCode = storage.getSalesPersonEmployeeCode()
        if (salesPersonEmployeeCode) {
          deliveryRequest.salesPersonEmployeeCode = salesPersonEmployeeCode
        }
        reserveMemberRequest.delivery = deliveryRequest
      }
      return reserveMemberRequest
    },
  },
  computed: {
    memberAddress() {
      const address = this.member.address1 + this.member.address2 + this.member.address3
      return ['〒' + this.member.formatZipCode(), address]
    },
    memberSex() {
      return this.member.sex === 0 ? '女' : '男'
    },
    showDeliveryInfo() {
      return this.member.selectedDelivery === 1
    },
    deliveryStatus() {
      return this.member.selectedDelivery === 1 ? '利用する' : '利用しない'
    },
    deliveryAddress() {
      if (this.delivery.selectedAddress === 1) {
        const address = this.delivery.address1 + this.delivery.address2 + this.delivery.address3
        return ['〒' + this.delivery.formatZipCode(), address]
      } else {
        return ['組合員情報の住所と同じ']
      }
    },
    deliveryPhoneNumber() {
      if (this.delivery.selectedTel === 1) {
        return this.delivery.phoneNumber
      } else {
        return '組合員情報の電話番号と同じ'
      }
    },
    deliveryResidence() {
      return this.residenceTextList[this.delivery.selectedResidence]
    },
    deliveryPlacement() {
      let text = this.placementTextList[this.delivery.selectedPlacement]
      if (this.delivery.selectedPlacement === 4) {
        return `${text}【${this.delivery.placementOtherInput}】`
      }
      return text
    },
    deliveryConfirmationOfDelivery() {
      return this.confirmationOfDeliveryTextList[this.delivery.selectedConfirmationOfDelivery]
    },
    deliverySelectionOfPaperCatalog() {
      return this.deliveryOfPaperCatalogTextList[this.delivery.selectedDeliveryOfPaperCatalog]
    },
    todokTrialCampaign() {
      return this.todokTrialCampaignTextList[this.delivery.selectedTodokTrialCampaign]
    },
    introduceText() {
      return this.delivery.selectedIntroducer === 1 ? 'はい、紹介です' : 'いいえ、紹介ではありません'
    },
    showIntroducerUnionNumber() {
      return this.delivery.selectedIntroducer === 1 && this.delivery.introducerUnionNumber
    },
    showIntroducerPhoneNumber() {
      return this.delivery.selectedIntroducer === 1 && this.delivery.introducerPhoneNumber
    },
    showChildInfo() {
      return this.member.selectedChildService === 1
    },
    childStatus() {
      return this.member.selectedChildService === 1 ? '利用する' : '利用しない'
    },
    childDateTitle() {
      return this.isSelectChild ? 'お子様の生年月日' : '出産予定日'
    },
    childImageTitle() {
      return this.isSelectChild ? '確認書類の画像' : '母子手帳（表紙）の画像'
    },
    isEmptyForImage() {
      return this.child.image === null
    },
    isSelectChild() {
      return this.child.selectedAboutChildren === Constants.CHILD_INFORMATION_INDEXES.CHILD
    },
  },
}
</script>
