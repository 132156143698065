export default class Constants {
  static CAPITAL_CONTRIBUTION_UNIT = 1000
  static LINK_BANNER_THEMES = Object.freeze({
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
  })
  static CHILD_INFORMATION_INDEXES = Object.freeze({
    PREGNANCY: 0,
    CHILD: 1,
  })
  // Web宅配申し込み トドックおためしキャンペーンの申込機能フラグ true:申込受付中, false:受付終了
  static FEATURE_FLAG_OPEN_TODOK_TRIAL_CAMPAIGN = true
}
